<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.APARTMENT_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.name_like.label }}</label>
              <app-input :name="filters.name_like.name" input-style="normal"
                        v-model="filters.name_like.value"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.admin_like.label }}</label>
              <app-input :name="filters.admin_like.name" input-style="normal"
                         v-model="filters.admin_like.value"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.address_like.label }}</label>
              <app-input :name="filters.address_like.name" input-style="normal"
                         v-model="filters.address_like.value"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.pref_id_equal.label }}</label>
              <app-select :name="filters.pref_id_equal.name"
                          input-style="normal"
                          v-model="filters.pref_id_equal.value"
                          :options-data="meta.prefectures"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>
      <template v-slot:body-cell-delete="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnDeleteClick(props.row)"
            class="btn btn-warning"
          >
            {{ $t("common.delete") }}
          </button>
        </td>
      </template>
      <template v-slot:body-cell-pref_id="props">
        <td class="app-align-middle min-w-100">
          {{ getPrefecture(props.row.pref_id) }}
        </td>
      </template>
      <template v-slot:body-cell-postal_code="props">
        <td class="app-align-middle text-center min-w-100">
          {{ getPostalCode(props.row.postal_code) }}
        </td>
      </template>
      <template v-slot:body-cell-updated_at="props">
        <td class="app-align-middle text-center min-w-150">
          <p data-original-title="2021-12-20" class="app-table-p app-cell-tooltip mb-0">{{ getDateUpdatedAt(props.row.updated_at) }}</p>
        </td>
      </template>

    </app-basic-table>

    <create-component
      ref="modal_create"
      @onSuccess="handleCreateSuccess"
    ></create-component>

    <update-component
      @onSuccess="handleUpdateSuccess"
      ref="modal_update"
    ></update-component>

    <app-modal-confirm
      ref="modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="handleConfirmDeleteClick"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
  </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import CreateComponent from "@pages/admin/users/_partials/CreateComponent";
import UpdateComponent from "@pages/admin/users/_partials/UpdateComponent";
import {ROLES, BASIC_TABLE} from "@constants";
import {__setLocalStorage} from "@utils";

export default {
  name: "ListAllMansion",

  components: {
    "app-basic-table": AppBasicTable,
    "create-component": CreateComponent,
    "update-component": UpdateComponent,
  },

  data() {
    return {
      idCache: null,
      paramGetList: {},
      filters: {
        name_like: {
          name: "name",
          condition: "like",
          label: this.$t("common.name")
        },
        admin_like: {
          name: "admin_email",
          condition: "like",
          label: this.$t("apartments.person_in_charge")
        },
        address_like: {
          name: "address",
          condition: "like",
          label: this.$t("apartments.address")
        },
        pref_id_equal: {
          name: "pref_id",
          condition: "equal",
          label: this.$t("apartments.prefecture")
        },
      },
      columns: [
        {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
        {name: "name", label: this.$t("common.name"), sortable: true},
        {name: "postal_code", label: this.$t("apartments.postal_code"), sortable: true},
        {name: "pref_id", label: this.$t("apartments.prefecture"), sortable: true},
        {name: "address", label: this.$t("apartments.address")},
        {name: "person_in_charge", label: this.$t("apartments.person_in_charge")},
        {name: "created_at", label: this.$t("common.created at"), textAlign: 'text-center', class: 'min-w-150', sortable: true},
        {name: "updated_at", label: this.$t("common.updated at"), textAlign: 'text-center', class: 'min-w-150', sortable: true},
        {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
      ],
      meta: {
        prefectures: [],
        admins: [],
      }
    };
  },

  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_APARTMENT).then(res => {
      let prefectures = _.cloneDeep(res.data.prefectures);
      prefectures.unshift({
        id: 'all',
        name: '',
      });
      this.meta = {
        prefectures: prefectures,
        admins: res.data.admins
      }
    })
  },

  methods: {
    handleBtnResentActiveEmailClick(entry) {
      this.$request
        .post(this.ENDPOINT.RESENT_ACTIVE_EMAIL(entry.id))
        .then((response) => {
          if (!response.hasErrors()) {
            this.__noticeSuccess(this.$t("users.sent_email_msg"));
          } else {
            this.__noticeError();
          }
        });
    },
    handleBtnCreateClick() {
      this.__setLocalStorage('apartment_search_url', window.location.href)
      this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_CREATE})
    },
    handleBtnUpdateClick(entry) {
      this.__setLocalStorage('apartment_search_url', window.location.href)
      this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_EDIT,params:  {id: entry.id}})
    },
    handleBtnDeleteClick(entry) {
      this.idCache = entry.id;
      this.$refs.modal_delete.show();
    },
    async handleConfirmDeleteClick() {
      let endpoint = this.ENDPOINT.USERS_DELETE;
      let response = await this.$request.delete(endpoint(this.idCache));
      if (!response.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        this.$refs.basic_table.getEntries();
      } else {
        this.__noticeError(response.data?.error_msg);
      }
      this.$refs.modal_delete.hide();
    },
    handleCreateSuccess(value) {
      this.$refs.basic_table.getEntries();
    },
    handleUpdateSuccess(value) {
      this.$refs.basic_table.getEntries();
    },
    getPrefecture(prefId) {
      let pref = this.meta.prefectures.find(({id}) => id === prefId);
      if (pref == undefined) {
        return '';
      }
      return pref.name;
    },
    getPostalCode(postalCode) {
      if (postalCode) {
        let pos = 3;
        let str1 = postalCode.substring(0, pos);
        let str2 = postalCode.substring(pos);
        return str1 + '-' + str2;
      }
      return '';
    },
    getDateUpdatedAt(updatedAt) {
      return this.__formatDate(updatedAt);
    },
    onResetAllSearch() {
      this.$router.push({name: this.$route.name,     query : {'filters.pref_id.equal' : 'all'}})
    },
    onSearch() {
    },
  },
};
</script>
